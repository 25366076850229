export const NAVBAR_DATA = {
  categories: [
    {
      id: 4,
      name: "Elektronica",
      slug: "/elektronica",
      children: [
        {
          id: 5673,
          name: "Beeld en geluid",
          slug: "/beeld_en_geluid",
          children: [
            {
              id: 8207,
              name: "Televisie",
              slug: "/televisie",
            },
            {
              id: 7715,
              name: "Soundbar",
              slug: "/soundbar",
            },
            {
              id: 5699,
              name: "Koptelefoon",
              slug: "/koptelefoon",
            },
            {
              id: 10750,
              name: "Wireless speaker",
              slug: "/wireless_speaker",
            },
            {
              id: 5686,
              name: "Receiver",
              slug: "/receiver",
            },
          ],
        },
        {
          id: 10779,
          name: "Mobiel",
          slug: "/mobiel",
          children: [
            {
              id: 5653,
              name: "Smartphone",
              slug: "/smartphone",
            },
            {
              id: 6616,
              name: "Tablet",
              slug: "/tablet",
            },
            {
              id: 12994,
              name: "Smartwatch",
              slug: "/smartwatch",
            },
            {
              id: 25756,
              name: "Smartband",
              slug: "/smartband",
            },
            {
              id: 5640,
              name: "Geheugenkaart",
              slug: "/geheugenkaart",
            },
          ],
        },
        {
          id: 5723,
          name: "Computers",
          slug: "/computers",
          children: [
            {
              id: 5726,
              name: "Laptop",
              slug: "/laptop",
            },
            {
              id: 6616,
              name: "Tablet",
              slug: "/tablet",
            },
            {
              id: 5729,
              name: "Monitor",
              slug: "/monitor",
            },
            {
              id: 5635,
              name: "All-in-one printer",
              slug: "/all-in-one_printer",
            },
            {
              id: 30541,
              name: "Online veiligheid",
              slug: "/online_veiligheid",
            },
          ],
        },
        {
          id: 19685,
          name: "Gaming",
          slug: "/gaming",
          children: [
            {
              id: 30470,
              name: "Playstation 5",
              slug: "/playstation_5",
            },
            {
              id: 30471,
              name: "Xbox Series",
              slug: "/xbox_series",
            },
            {
              id: 28834,
              name: "Nintendo Switch",
              slug: "/nintendo_switch",
            },
            {
              id: 28832,
              name: "Playstation 4",
              slug: "/playstation_4",
            },
            {
              id: 28833,
              name: "Xbox One",
              slug: "/xbox_one",
            },
          ],
        },
        {
          id: 5625,
          name: "Foto en video",
          slug: "/foto_en_video",
          children: [
            {
              id: 5627,
              name: "Digitale camera",
              slug: "/digitale_camera",
            },
            {
              id: 8622,
              name: "Systeemcamera",
              slug: "/systeemcamera",
            },
            {
              id: 5628,
              name: "Spiegelreflexcamera",
              slug: "/spiegelreflexcamera",
            },
            {
              id: 5641,
              name: "Objectief",
              slug: "/objectief",
            },
            {
              id: 5629,
              name: "Videocamera",
              slug: "/videocamera",
            },
          ],
        },
      ],
    },
    {
      id: 5,
      name: "Huis",
      slug: "/huis",
      children: [
        {
          id: 30421,
          name: "Thuiswerken",
          slug: "/thuiswerken",
          children: [
            {
              id: 5729,
              name: "Monitor",
              slug: "/monitor",
            },
            {
              id: 5736,
              name: "Toetsenbord",
              slug: "/toetsenbord",
            },
            {
              id: 5731,
              name: "Computermuis",
              slug: "/computermuis",
            },
            {
              id: 5645,
              name: "Webcam",
              slug: "/webcam",
            },
            {
              id: 8747,
              name: "Headset",
              slug: "/headset",
            },
          ],
        },
        {
          id: 5866,
          name: "Huishouden",
          slug: "/huishouden",
          children: [
            {
              id: 5882,
              name: "Wasmachine",
              slug: "/wasmachine",
            },
            {
              id: 5881,
              name: "Wasdroger",
              slug: "/wasdroger",
            },
            {
              id: 5899,
              name: "Stofzuiger",
              slug: "/stofzuiger",
            },
            {
              id: 5880,
              name: "Vaatwasser",
              slug: "/vaatwasser",
            },
            {
              id: 5895,
              name: "Strijkijzer",
              slug: "/strijkijzer",
            },
          ],
        },
        {
          id: 19710,
          name: "Koken en tafelen",
          slug: "/koken_en_tafelen",
          children: [
            {
              id: 5869,
              name: "Vriezer",
              slug: "/vriezer",
            },
            {
              id: 5876,
              name: "Koelkast",
              slug: "/koelkast",
            },
            {
              id: 5980,
              name: "Espressomachine",
              slug: "/espressomachine",
            },
            {
              id: 5968,
              name: "Frituurpan",
              slug: "/frituurpan",
            },
            {
              id: 5971,
              name: "Keukenmachine",
              slug: "/keukenmachine",
            },
          ],
        },
        {
          id: 11841,
          name: "Wonen",
          slug: "/wonen",
          children: [
            {
              id: 30519,
              name: "Airco's",
              slug: "/aircos",
            },
            {
              id: 30513,
              name: "Mobiele airco",
              slug: "/mobiele_airco",
            },
            {
              id: 5891,
              name: "Ventilator",
              slug: "/ventilator",
            },
            {
              id: 5889,
              name: "Luchtreiniger",
              slug: "/luchtreiniger",
            },
            {
              id: 6599,
              name: "Rookmelder",
              slug: "/rookmelder",
            },
          ],
        },
        {
          id: 19682,
          name: "Energie",
          slug: "/energie",
          children: [
            {
              id: 30521,
              name: "Laadpaal",
              slug: "/laadpaal",
            },
            {
              id: 30529,
              name: "Warmtepompen",
              slug: "/warmtepompen",
            },
            {
              id: 5886,
              name: "Cv-ketel",
              slug: "/cv-ketel",
            },
            {
              id: 20131,
              name: "Zonnepanelen",
              slug: "/zonnepanelen",
            },
            {
              id: 13501,
              name: "Energie vergelijken",
              slug: "/energie_vergelijken",
            },
          ],
        },
      ],
    },
    {
      id: 1,
      name: "Auto & Fiets",
      slug: "/auto_fiets",
      children: [
        {
          id: 19696,
          name: "Auto",
          slug: "/auto",
          children: [
            // {
            //   id: 30547,
            //   name: "Elektrische auto",
            //   slug: "/elektrische_auto",
            // },
            {
              id: 30521,
              name: "Laadpaal",
              slug: "/laadpaal",
            },
            {
              id: 5775,
              name: "Autoradio",
              slug: "/autoradio",
            },
            {
              id: 5662,
              name: "Navigatie systeem",
              slug: "/navigatie_systeem",
            },
            {
              id: 13375,
              name: "Dashcam",
              slug: "/dashcam",
            },
          ],
        },
        {
          id: 19691,
          name: "Fiets",
          slug: "/fiets",
          children: [
            {
              id: 7138,
              name: "Elektrische fiets",
              slug: "/elektrische_fiets",
            },
            {
              id: 30535,
              name: "Elektrische bakfietsen",
              slug: "/elektrische_fiets/elektrische_bakfietsen",
            },
            {
              id: 5793,
              name: "Fietsendrager",
              slug: "/fietsendrager",
            },
            {
              id: 12366,
              name: "Fietsslot",
              slug: "/fietsslot",
            },
            {
              id: 7178,
              name: "Fietsstoeltje",
              slug: "/fietsstoeltje",
            },
          ],
        },
      ],
    },
    {
      id: 9,
      name: "Tuin & Klussen",
      slug: "/tuin_klussen",
      children: [
        {
          id: 19727,
          name: "Tuin",
          slug: "/tuin",
          children: [
            {
              id: 5897,
              name: "Hogedrukreiniger",
              slug: "/hogedrukreiniger",
            },
            {
              id: 5950,
              name: "Grasmaaier",
              slug: "/grasmaaier",
            },
            {
              id: 8455,
              name: "Heggenschaar",
              slug: "/heggenschaar",
            },
            {
              id: 5902,
              name: "Barbecue",
              slug: "/barbecue",
            },
            {
              id: 5961,
              name: "Terrasverwarmer",
              slug: "/terrasverwarmer",
            },
          ],
        },
        {
          id: 5947,
          name: "Klussen",
          slug: "/klussen",
          children: [
            {
              id: 5957,
              name: "Boormachine",
              slug: "/boormachine",
            },
            {
              id: 5956,
              name: "Decoupeerzaag",
              slug: "/decoupeerzaag",
            },
            {
              id: 5958,
              name: "Schuurmachine",
              slug: "/schuurmachine",
            },
            {
              id: 8587,
              name: "Cirkelzaag",
              slug: "/cirkelzaag",
            },
            {
              id: 9538,
              name: "Multitool",
              slug: "/multitool",
            },
          ],
        },
        {
          id: 30249,
          name: "Dier",
          slug: "/dier",
          children: [
            {
              id: 28986,
              name: "Hondenmand",
              slug: "/dierbenodigdheden/hondenmand",
            },
            {
              id: 29030,
              name: "Hondenvoer",
              slug: "/dierbenodigdheden/hondenvoer",
            },
            {
              id: 29038,
              name: "Hondentuigje",
              slug: "/dierbenodigdheden/hondentuigje",
            },
            {
              id: 28988,
              name: "Kattenbak",
              slug: "/dierbenodigdheden/kattenbak",
            },
            {
              id: 29204,
              name: "Kattenverjager",
              slug: "/dierbenodigdheden/kattenverjager",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Speelgoed & Kids",
      slug: "/speelgoed_kids",
      children: [
        {
          id: 13621,
          name: "Speelgoed",
          slug: "/speelgoed",
          children: [
            {
              id: 5725,
              name: "Console",
              slug: "/console",
            },
            {
              id: 27449,
              name: "Radiografisch Speelgoed",
              slug: "/elektronisch_speelgoed/radiografisch_speelgoed",
            },
            {
              id: 27020,
              name: "Bouw en constructiespeelgoed",
              slug: "/bouw_en_constructiespeelgoed",
            },
            {
              id: 27059,
              name: "Elektronisch speelgoed",
              slug: "/elektronisch_speelgoed",
            },
            {
              id: 27071,
              name: "Puzzel en spel",
              slug: "/puzzel_en_spel",
            },
          ],
        },
        {
          id: 5702,
          name: "Baby en Peuter",
          slug: "/baby_en_peuter",
          children: [
            {
              id: 5704,
              name: "Autostoeltje",
              slug: "/autostoeltje",
            },
            {
              id: 5708,
              name: "Kinderwagen",
              slug: "/kinderwagen",
            },
            {
              id: 5939,
              name: "Koortsthermometer",
              slug: "/koortsthermometer",
            },
            {
              id: 5705,
              name: "Babyfoon",
              slug: "/babyfoon",
            },
            {
              id: 24783,
              name: "Babyfoon met camera",
              slug: "/babyfoon/beeldbabyfoon",
            },
          ],
        },
      ],
    },
    {
      id: 7,
      name: "Verzorging",
      slug: "/verzorging",
      children: [
        {
          id: 25684,
          name: "Verzorgen",
          slug: "/verzorgen",
          children: [
            {
              id: 5929,
              name: "Elektrische tandenborstel",
              slug: "/elektrische_tandenborstel",
            },
            {
              id: 5914,
              name: "Scheerapparaat",
              slug: "/scheerapparaat",
            },
            {
              id: 5913,
              name: "Trimmer",
              slug: "/trimmer",
            },
            {
              id: 5918,
              name: "Fohn",
              slug: "/fohn",
            },
          ],
        },
      ],
    },
    {
      id: 8,
      name: "Sporten",
      slug: "/sporten",
      children: [
        {
          id: 28155,
          name: "Sport",
          slug: "/sport",
          children: [
            {
              id: 5925,
              name: "Loopband",
              slug: "/loopband",
            },
            {
              id: 5926,
              name: "Roeitrainer",
              slug: "/roeitrainer",
            },
            {
              id: 5924,
              name: "Hometrainer",
              slug: "/hometrainer",
            },
            {
              id: 5927,
              name: "Spinbike",
              slug: "/spinbike",
            },
            {
              id: 13390,
              name: "Fietstrainer",
              slug: "/fietstrainer",
            },
          ],
        },
        {
          id: 28184,
          name: "Smart Wearables",
          slug: "/smart_wearables",
          children: [
            {
              id: 12994,
              name: "Smartwatch",
              slug: "/smartwatch",
            },
            {
              id: 28842,
              name: "Smartwatch dames",
              slug: "/smartwatch/dames",
            },
            {
              id: 14046,
              name: "Smartband",
              slug: "/smartband",
            },
            {
              id: 5936,
              name: "Hartslagmeter",
              slug: "/hartslagmeter",
            },
          ],
        },
      ],
    },
    {
      id: 19,
      name: "Besparen",
      slug: "/besparen",
      children: [
        {
          id: 5812,
          name: "Verzekeringen & Geld",
          slug: "/verzekeringen_geld",
          children: [
            {
              id: 13501,
              name: "Energie vergelijken",
              slug: "/energie_vergelijken",
            },
            {
              id: 13636,
              name: "Autoverzekering",
              slug: "/autoverzekering",
            },
            {
              id: 2919921,
              name: "Zorgverzekering vergelijken",
              slug: "/zorgverzekering_vergelijken",
            },
          ],
        },
      ],
    },
  ],
};

export const NAVBAR_CONTENT: Record<
  string,
  { title: string; subtitle: string; link: string }[]
> = {
  "/verzorging": [
    {
      title: "Parfum",
      subtitle: "De lekkerste luchtjes voor hem of haar",
      link: "/parfum",
    },
  ],
  "/sporten": [
    {
      title: "Sporthorloges",
      subtitle: "Bekijk het aanbod sporthorloges",
      link: "/smartwatch#sensoren:hartslag,afstand",
    },
  ],
  "/besparen": [
    {
      title: "Zorgverzekeringen",
      subtitle: "Vind de beste zorgverzekering",
      link: "/zorgverzekering_vergelijken",
    },
  ],
};
