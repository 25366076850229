import { Button } from "ui/button";
import { signOut } from "next-auth/react";
import { Icon } from "ui/icon";
import { NAVBAR_DATA } from "../utils/constants";
import { useEffect, useState, type MouseEvent } from "react";
import { cn } from "ui/utils";
import { $session, setSession } from "stores/session";
import { SearchInput } from "ui/search-input-new";
import { useStore } from "@nanostores/react";
import type { Category } from "api-v2/products/types";
import type { Category as NavbarCategory } from "../utils";
import { useMediaQuery } from "usehooks-ts";
import { BapAd } from "ui/bap-ad";

type NavigationProps = {
  initialCategory: NavbarCategory | null;
  categories: Category[];
};

const Navigation = ({ initialCategory, categories }: NavigationProps) => {
  const session = useStore($session);

  const isMobile = useMediaQuery("(max-width: 768px)");
  const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [selectedCategory, setSelectedCategory] =
    useState<NavbarCategory | null>(
      initialCategory || NAVBAR_DATA.categories[0] || null
    );

  useEffect(() => {
    if (!isMobile) {
      setSelectedCategory(initialCategory || NAVBAR_DATA.categories[0] || null);
    } else {
      setSelectedCategory(null);
    }
  }, [isMobile]);

  const handleToggleMenu = () => {
    setShow((prev) => !prev);
  };
  const handleToggleSearch = () => {
    setShowSearch((prev) => !prev);
  };
  const handleToggleUser = () => {
    setShowUser((prev) => !prev);
  };

  const handleBack = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    setSelectedCategory(null);
  };

  const handleLogout = () => {
    signOut();
    setSession(null);
    setShowUser(false);
  };

  return (
    <header className="peer fixed top-0 left-0 w-screen bg-header text-header-foreground z-40 text-base">
      <div className="h-[50px] p-4 md:h-[80px] md:flex md:items-center md:container md:relative lg:px-0">
        <div className="absolute left-2.5 top-1/2 -translate-y-1/2 md:hidden">
          <Button
            variant="ghost"
            size="icon"
            className="uppercase text-header-foreground hover:text-header-foreground hover:opacity-80"
            onClick={handleToggleMenu}
          >
            <Icon icon="hamburger" size={20} />
          </Button>
        </div>

        <a
          href="/"
          className="logo absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-[150px] h-full md:w-[196px] md:relative md:left-0 md:top-0 md:translate-x-0 md:translate-y-0 md:z-30"
        >
          <span className="sr-only">Terug naar de homepage</span>
        </a>

        <SearchInput
          categories={categories}
          className={cn(
            "absolute top-full left-1/2 -translate-x-1/2 hidden md:top-1/2 md:-translate-y-1/2 md:block",
            { block: showSearch }
          )}
        />

        <div className="hidden absolute right-2.5 p-2 top-1/2 -translate-y-1/2 md:flex md:gap-4">
          {!session && (
            <Button
              variant="ghost"
              asChild
              className="text-header-foreground hover:text-header-foreground hover:opacity-80"
            >
              <a href="/login">
                <Icon icon="profile" width={12} height={16} />
                Inloggen
              </a>
            </Button>
          )}

          {session && (
            <Button
              variant="ghost"
              className="max-w-28 justify-start text-header-foreground"
              onClick={handleToggleUser}
            >
              <Icon
                icon="profile"
                width={12}
                height={16}
                className="flex-shrink-0"
              />
              <span className="truncate block">{session.user.username}</span>
            </Button>
          )}

          <Button
            variant="ghost"
            className="text-header-foreground hover:text-header-foreground hover:opacity-80"
            onClick={handleToggleMenu}
          >
            <Icon icon={show ? "close" : "hamburger"} size={16} />
            Categorieën
          </Button>
        </div>

        <div className="absolute right-2.5 top-1/2 -translate-y-1/2 md:hidden">
          <Button
            variant="ghost"
            size="icon"
            className="uppercase text-header-foreground hover:text-header-foreground hover:opacity-80"
            onClick={handleToggleSearch}
          >
            {!showSearch && <Icon icon="search" size={20} />}
            {showSearch && <Icon icon="close" size={28} />}
          </Button>
        </div>

        {showUser && (
          <ul className="absolute right-0 top-[80px] bg-background border border-t-0 w-full max-w-[220px] shadow-sm rounded-b-sm">
            <li>
              <a
                href="/profiel/mijn-kieskeurig"
                className="flex items-center text-foreground px-4 py-3"
              >
                <span className="flex-1">Profiel wijzigen</span>{" "}
                <Icon icon="chevronLightRight" size={16} />
              </a>
            </li>
            <li className="border-t">
              <button
                className="flex items-center text-destructive px-4 py-3 w-full text-left hover:underline"
                onClick={handleLogout}
              >
                <span className="flex-1">Uitloggen</span>{" "}
                <Icon icon="chevronLightRight" size={16} />
              </button>
            </li>
          </ul>
        )}
      </div>

      <nav
        className={cn(
          "fixed top-0 left-0 w-screen h-full bg-popover text-popover-foreground flex flex-col z-50 text-base",
          "md:top-[80px] md:h-auto",
          { hidden: !show }
        )}
      >
        <div
          className="h-[60px] min-h-[60px] px-5 flex items-center bg-muted text-muted-foreground justify-between md:hidden"
          role="button"
          onClick={handleToggleMenu}
        >
          <div className="flex gap-1 items-center text-sm">
            <Icon icon="hamburger" size={16} />
            Menu
          </div>

          <Icon icon="close" size={16} />
        </div>

        <ul
          className={cn(
            "container flex flex-col justify-between px-0 overflow-x-hidden overflow-y-scroll pb-20",
            "md:flex-row md:border-b md:pb-0 md:overflow-y-hidden"
          )}
        >
          <BapAd
            variant="compact"
            className="rounded-none"
            properties={{
              contenttype: "navigation",
            }}
            mobile
          />

          <li
            className={cn("p-4 flex flex-col gap-4 md:hidden", {
              hidden: selectedCategory,
            })}
          >
            <Button variant="primary" fullWidth asChild>
              <a href={session ? "/profiel/mijn-kieskeurig" : "/login"}>
                {session
                  ? `Mijn kieskeurig (${session.user.username})`
                  : "Inloggen"}
              </a>
            </Button>

            {session && (
              <Button
                variant="ghost"
                className="text-header-foreground hover:text-header-foreground hover:opacity-80"
                fullWidth
                onClick={handleLogout}
              >
                Uitloggen
              </Button>
            )}
          </li>
          {NAVBAR_DATA.categories
            .filter((category) => category.slug !== "overig")
            .map((category, index) => (
              <li
                key={category.id}
                className={cn(
                  "relative cursor-pointer border-t",
                  "md:border-none md:block md:p-4",
                  {
                    hidden:
                      selectedCategory && selectedCategory?.id !== category.id,
                    "md:pl-0": index === 0,
                  }
                )}
                onMouseEnter={() => setSelectedCategory(category)}
                onClick={() => setSelectedCategory(category)}
              >
                <span
                  className={cn(
                    "p-4 flex justify-between text-primary items-center",
                    "md:text-foreground md:justify-start md:items-start md:p-0",
                    {
                      "justify-start gap-1 bg-muted text-muted-foreground text-sm":
                        selectedCategory?.id === category.id,
                      "md:bg-transparent text-foreground md:flex md:opacity-0 md:gap-0 md:text-base":
                        selectedCategory?.id === category.id,
                    }
                  )}
                >
                  <span
                    className={cn("hidden md:hidden", {
                      "inline-block": selectedCategory?.id === category.id,
                    })}
                  >
                    Je bent nu in:
                  </span>
                  {category.name}

                  <Icon
                    icon="chevronLightRight"
                    size={16}
                    className={cn("md:hidden", {
                      hidden: selectedCategory?.id === category.id,
                    })}
                  />
                </span>

                <span
                  className={cn(
                    "w-full block absolute -translate-y-1/2 top-1/2 font-bold opacity-0",
                    "md:text-center md:left-1/2 md:-translate-x-1/2",
                    {
                      "md:opacity-100": selectedCategory?.id == category.id,
                      "md:text-left": index === 0,
                    }
                  )}
                >
                  {category.name}
                </span>

                <span
                  className={cn(
                    "hidden text-sm w-full p-4 border-b z-10",
                    "md:hidden",
                    {
                      block: selectedCategory?.id == category.id,
                    }
                  )}
                  role="button"
                  onClick={handleBack}
                >
                  <Icon icon="arrowLeft" size={16} />
                  Terug
                </span>

                <nav
                  className={cn(
                    "px-4 hidden relative",
                    "md:fixed md:top-[137px] md:left-0 md:bg-popover md:w-screen md:z-50 md:p-0",
                    { flex: selectedCategory?.id === category.id }
                  )}
                >
                  <ul
                    className={cn(
                      "flex flex-col gap-4 w-full",
                      "md:grid md:grid-cols-5 md:container md:py-4"
                    )}
                  >
                    {category.children.map((level2) => (
                      <li key={level2.id} className="text-lg md:font-bold">
                        <a
                          href={level2.slug}
                          className="inline-block text-foreground nav__link nav__link--level2 py-4 md:py-0"
                        >
                          {level2.name}
                        </a>
                        <ul className="flex flex-col md:mt-2 md:gap-2">
                          {level2.children.map((level3) => (
                            <li
                              key={level3.id}
                              className={cn(
                                "text-base font-normal border-t py-2.5",
                                "md:border-none md:py-0"
                              )}
                            >
                              <a
                                href={level3.slug}
                                className={cn(
                                  "flex justify-between items-center text-primary nav__link nav__link--level3",
                                  "md:text-foreground md:items-start md:justify-start"
                                )}
                              >
                                {level3.name}

                                <Icon
                                  icon="chevronLightRight"
                                  size={16}
                                  className="md:hidden"
                                />
                              </a>
                            </li>
                          ))}
                          <li className="text-sm font-normal hidden md:block">
                            <a
                              href={level2.slug}
                              className="text-primarynav__link nav__link--more"
                            >
                              Meer
                            </a>
                          </li>

                          <li className="pt-2.5 md:hidden">
                            <a
                              href={level2.slug}
                              className="text-sm text-primary bg-primary/10 px-2.5 flex gap-2 items-center justify-between py-2.5 rounded nav__link nav__link--more"
                            >
                              Bekijk alle categorieën
                              <Icon icon="plus" size={12} />
                            </a>
                          </li>
                        </ul>
                      </li>
                    ))}

                    <BapAd
                      variant="leaderboard"
                      properties={{
                        ubercategory: category.slug.replace("/", ""),
                        contenttype: "navigation",
                      }}
                      desktop
                      className="col-span-full"
                    />
                  </ul>
                </nav>
              </li>
            ))}

          <li
            className={cn(
              "relative cursor-pointer border-t",
              "md:border-none md:block md:p-4 md:pr-0",
              {
                hidden: selectedCategory && selectedCategory?.id !== -10,
              }
            )}
            onMouseEnter={() =>
              setSelectedCategory({
                id: -10,
                slug: "/prijsdalers",
                name: "Prijsdalers",
              })
            }
            onClick={() => setSelectedCategory(null)}
          >
            <a
              href="/prijsdalers"
              className="hover:no-underline text-foreground"
            >
              <span
                className={cn(
                  "p-4 flex justify-between text-primary items-center",
                  "md:text-foreground md:justify-start md:items-start md:p-0",
                  {
                    "justify-start gap-1 bg-muted text-muted-foreground text-sm":
                      selectedCategory?.id === -10,
                    "md:bg-transparent text-foreground md:flex md:opacity-0 md:gap-0 md:text-base":
                      selectedCategory?.id === -10,
                  }
                )}
              >
                Prijsdalers
                <Icon
                  icon="chevronLightRight"
                  size={16}
                  className={cn("md:hidden", {
                    hidden: selectedCategory?.id === -10,
                  })}
                />
              </span>

              <span
                className={cn(
                  "w-full block absolute -translate-y-1/2 top-1/2 font-bold opacity-0",
                  "md:text-right md:left-1/2 md:-translate-x-1/2",
                  {
                    "md:opacity-100": selectedCategory?.id == -10,
                  }
                )}
              >
                Prijsdalers
              </span>
            </a>
          </li>
        </ul>
      </nav>

      <div
        className={cn("bg-black opacity-30 absolute h-screen w-full", {
          hidden: !show,
        })}
        onClick={handleToggleMenu}
      />
    </header>
  );
};

export default Navigation;
